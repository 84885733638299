.stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.step-circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background:linear-gradient(135deg, rgba(229, 157, 41, 0.6) 0%, rgba(156, 7, 248, 0.6) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    padding-top:5px;
    /* padding-bottom:5px; */
}

.step-label {
    font-size: 14px;
    color:#D48CCC;
    font-weight: 500;
}

.step.active .step-circle {
    background: #6F57E9;
    
}
.connector {
    width: 100px;
    height: 2px;
    border-bottom: 22px dotted #ccc;
    margin-right: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 8px);
}
