@import '~antd/dist/antd.css';

:root {
  --login-popup-background : #F4F6FC;
  --login-popup-card-background : #fff;
  --login-popup-card-box-shadow : 4px 4px 11px 0px rgba(208, 219, 247, 0.15), -2px -2px 10px 0px rgba(208, 219, 247, 0.15); 
  --login-popup-primary-color : #6f57e9;
  --login-popup-private-color : #000;
  --login-popup-svg-filter :invert(0%) sepia(2%) saturate(21%) hue-rotate(50deg) brightness(100%) contrast(101%);
  --login-popup-card-border : none;
  --login-popup-skeleton-background : linear-gradient(120deg,#e5e5e5 30%,#f0f0f0 38%,#f0f0f0 40%,#e5e5e5 48%);
  --grey-lite: #e0e0e0;
  --grey-lite-bg: #f6f6f6;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'proxima-nova', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  ::selection {
    color: #fff;
    background-color: #205fbf;
  }
  
  ::-moz-selection {
    color: #fff;
    background-color: #205fbf;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .react-reveal {
    height: 354px !important;
  }
  
  .mb-16 {
    margin-bottom: 16px !important;
  }
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .mb-32 {
    margin-bottom: 32px !important;
  }
  .mb-36 {
    margin-bottom: 32px !important;
  }
  .mb-21 {
    margin-bottom: 21px !important;
  }
  .mb-12 {
    margin-bottom: 12px !important;
  }
  .mb-8 {
    margin-bottom: 8px !important;
  }
  .mt-8 {
    margin-top: 8px !important;
  }
  .mt-21 {
    margin-top: 21px !important;
  }
  .mt-12 {
    margin-top: 12px !important;
  }
  .mt-27 {
    margin-top: 27px !important;
  }
  .mr-12 {
    margin-right: 12px !important;
  }
  .mr-20 {
    margin-right: 20px !important;
  }
  .mr-8 {
    margin-right: 8px !important;
  }
  

  @media screen and (max-width: 576px) {
    .react-tour-pickrr {
      max-width: 350px !important;
    }
  }
  
  .ant-table-filter-dropdown-btns .ant-btn {
    background: #263f97 !important;
    color: white !important;
  }
  
  /* Pulse */
  @-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    75% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  .hvr-pulse {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  .customize-mask .ant-image-preview-operations {
    background: rgb(0 0 0 / 73%);
  }
  .customize-mask .ant-image-preview-switch-left,
  .customize-mask .ant-image-preview-switch-right {
    background: rgb(0 0 0 / 73%);
  }
  
  .videoModal .ant-modal-close {
    right: -20px;
    top: -20px;
  }

  .ant-steps-item-title {
    color: #0C3784 !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.03em;
    text-align: left;
  }
  
  .ant-radio-group span {
    color: #0C3784 !important;
  }

  .ant-tabs-nav {
    margin-top: 5px! important;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #263f97;
    opacity: 1;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #263f97;
    opacity: 1;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    color: #6F57E9;
    opacity: 1;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-family: 'proxima-nova', sans-serif;
  }
  .ant-tabs-tab {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #6F57E9;
    opacity: 0.6;
  }
  .ant-tabs-ink-bar {
    background-color:#6F57E9;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }

  .ant-table-filter-dropdown .ant-dropdown-menu-item {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #0C3784
  }

  .ant-table-filter-dropdown {
    min-width: 195px! important;
    width: 100%! important;
    padding: 0px;
    border-radius: 6.5px;
  }
  
  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
  }

  .ant-table-filter-trigger:hover { 
    color: none;
    background: none;
  }

  .ant-picker-dropdown {
    position: fixed;
  }
  .card-container-login{
    width: 89%;
    height:30%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 8px;
    background:  var(--login-popup-card-background);
    margin:12px;
    box-shadow: var(--login-popup-card-box-shadow);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    gap: 5px;
    border : var(--login-popup-card-border);
    box-shadow: 3.84px 3.84px 10.57px 0px #D0DBF726;
    box-shadow: -1.92px -1.92px 9.61px 0px #D0DBF726;
  }
  .confirmPhoneContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .loginPopupPrimaryButton {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    background: var(--login-popup-primary-color);
    border: none;
    outline: none;
    cursor: pointer;
    min-height: 40px;
  
    /* typography */
    color: var(--White, #FFF);
    text-align: center;
    font-family: 'Metropolis';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
  }

  .info_text_login_popup{
    font-family: Product Sans;
    font-size: 8.99px;
    font-weight: 400;
    line-height: 11.22px;
    text-align: center;
    width:75%;
    color: var(--login-popup-private-color);
    align-items:center
  }

  .container2{
    margin-bottom: 4px;
    justify-content: center;
  }

  .sr-logo{
    height: 13px;
    width: 60px;
    position: relative;
    margin-left:5px
  }
  .d-flex {
    display: flex;
  }

  .h6 {
    font-size: 10px;
  }

  input:focus {
    border: 1px solid cornflowerblue! important;
  }

  .no-scrollbar {
    overflow: auto;
  
    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
  
    scrollbar-width: none; /* For Firefox */
  }