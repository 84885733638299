/* body, html { 
    font-family: Roboto, sans-serif;  
    float: left; 
    width: 100%; 
    margin: 0px; 
    padding: 0px; 
  } */
  
  .form-md { 
    width: 100%; 
    text-align: center; 
    margin: 32px auto 32px auto; 
  }
  .form-md .formGroup {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
  }
  .formGroup {
    margin-top: 20px;
    margin-bottom: 16px;
    position: relative;
  }
  .input {
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid #B5B5B5BD;
    border-radius: 4px;
    background-color: transparent;
    margin: 0px auto;
    padding: 6px 4px 4px 14px;
    height: 40px;
    outline: none !important;
    font-size: 16px;
    color: black;
    transition: all .2s ease-in-out;
  }
  
  .label {
    position: absolute;
    top: 14px; 
    left: 12px;
    text-align: left;
    display: inline-block;
    padding: 0 4px;
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    margin: 0px auto;
    cursor: text;
    transition: all .15s ease-in-out;
  }
  /* input:hover, input:focus { border: 1px solid #B5B5B5BD }
  
  input:valid + label, input:focus + label { 
    top: -6px;
    color: #B5B5B5BD;
  } */
  .gl-form-asterisk {
      background-color: inherit;
      color: #e32b2b;
      padding: 0;
      padding-left: 3px;
     
  }
  .gl-form-asterisk:after {
      content: "*";
  }
  
  
  
  