.inputGroup {
  position: relative;
  height: 35%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: 6px;
}

.inputGroup .inputAddon {
  height:100%;
  width:25%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--grey-lite);
  padding: 0 10px;
}
.inputGroup .inputAddonRight {
  height:100%;
  box-sizing: border-box;
  display: flex;
  width:25%;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 0px 5px 5px 0;
  font-size: 14px;
}

input:focus {
  border: 1px solid var(--secondary-color);
}
.inputCard {
  border-radius: 6px;
  background: var(--card-backgorund-cta-button-text, #FFF);
}

.inputCard:focus {
  border: 1px solid var(--input-box-selected-text-liner, rgba(49, 115, 227, 0.80)) !important;
}

.inputLoginPopup{
  border-radius: 6px;
  background: var(--login-popup-card-background);
  box-shadow: none;
  color: var(--login-popup-private-color );
}


.inputLoginPopup::placeholder{
  font-size: 12px;
  margin:auto;
}
.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.disablenew {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 367px;
  z-index: 5;
}

.error_message{
  color: rgb(255, 0, 0);
  font-size:12px;
  margin-top: 4px; 
}

.card_sr_promise {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  border-radius: 8px;
  opacity: 1;
  margin: 12px 0px 0px 0px;
}
.hr{
  width: 90%;
  color: #EDEDED;
  opacity: 1;
}
.header_icon{
  margin-top: 23px;
  margin-bottom: 7px;
  margin-left: 25px;
}

.header_icon_card{
  margin-top: 0px;
  margin-bottom: 7px;
  margin-left: 0px;
}

.bgCheckoutFeature {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  margin: 0 0 53px;
  justify-content: center;
}

.bgCheckoutFeature li {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  color: var(--primary-color);
  border-right: 1px solid #f6f6f6;
  text-align: center;
  width: 33.33%;
}
.bgCheckoutFeature li span.icon {
  width: 40px;
  height: 40px;
  background: var(--white);
  border-radius: 50%;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgCheckoutFeature li:last-child {
  border-right: 0;
}
.bgCheckoutFeature li img {
  width: 40px;
  height: 40px;
}
.sr_promise{
  padding-top: 10px;
}
.sr_promise_text{
  color: #5968BE;
opacity: 1;
}

.info_text {
  font-family: Metropolis;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: left;
  color: #717171;
  margin-top: 4px;
  margin-left: 4px;
}
.info_text_login_popup{
  font-family: 'Metropolis';
  font-size: 11px;
  font-weight: 450;
  line-height: 12.49px;
  color: var(--login-popup-private-color);
  margin-left: 4px;
}