:root {
    --login-popup-background : #F4F6FC;
    --login-popup-card-background : #fff;
    --login-popup-card-box-shadow : 4px 4px 11px 0px rgba(208, 219, 247, 0.15), -2px -2px 10px 0px rgba(208, 219, 247, 0.15); 
    --login-popup-primary-color : #6f57e9;
    --login-popup-private-color : #000;
    --login-popup-svg-filter :invert(0%) sepia(2%) saturate(21%) hue-rotate(50deg) brightness(100%) contrast(101%);
    --login-popup-card-border : none;
    --login-popup-skeleton-background : linear-gradient(120deg,#e5e5e5 30%,#f0f0f0 38%,#f0f0f0 40%,#e5e5e5 48%)
}


.headingLoginPopup{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--login-popup-private-color);
    margin-bottom: 16px;
}

.login-popup-private-color {
    color: var(--login-popup-private-color) !important;
}

/* light theme colors 

background : #F4F6FC;
card-background : #FFF;
card-box-shadow : 4px 4px 11px 0px rgba(208, 219, 247, 0.15), -2px -2px 10px 0px rgba(208, 219, 247, 0.15); 
svg filter : invert(0%) sepia(2%) saturate(21%) hue-rotate(50deg) brightness(100%) contrast(101%)
*/


/* dark theme colors 

background : #1B1B1C;
card-background : #121212;
card-box-shadow : ''; 
svg filter : invert(100%) sepia(100%) saturate(0%) hue-rotate(20deg) brightness(103%) contrast(102%)
*/


.Container {
    margin: auto;
    position: absolute;
    top:0;
    width: 100%;
    height:100%;
    /* top: 152px; */
    /* left: 15px; */
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    border-radius: 22px;
    display: flex;
    /* justify-content: center; */    
    flex-direction: column;
    border-radius: 22px;
    gap: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    /* Default width */

  

    /* Scrollbar customization */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.Container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.close {
    position: fixed;
    cursor: pointer;
    z-index: 999;
    right: calc(50% - 8px);
    top: calc(50% - min(290px,calc(40vh + 30px)));
}

.subtext{
    height:25%;
    font-family: Product Sans;
    font-size: 11.19px;
    font-weight: 700;
    line-height: 13.37px;
    text-align: center;
    
}

.loginPopupBody{
    position:relative;
    display: flex;
    flex-direction : column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin:auto;
    gap:7.81px
}

.trustBandLoginPopup {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    margin: 10px auto;
    border-radius: 4px;
    gap: 10px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    background: #FFF;
    box-shadow: 3px 3px 8px 0px rgba(208, 219, 247, 0.15), -1.5px -1.5px 8px 0px rgba(208, 219, 247, 0.15);
    text-align: center;
    /* typography */
    font-family: 'Cirka';
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
}

.loginPopupFooter{
    margin-top: -8px;
}
.loginPopupTopContainer{
    display: flex;
    flex-direction: column;
}

.previewContainer {
    display: flex;
    justify-content: center;
    margin:auto;
background-image: url('../../images/iPhone 15 Pro Black Titanium Mockup Portrait.png');
background-repeat: no-repeat;
width: 393px;
height: 852px;
top: 9.06px;
left: 10.7px;
gap: 0px;
background-size: 100% 100%;



}


.overLayMainContainer{
    position: relative;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center;    
    margin: auto;
    height: 100%;
    width: 75%;
    /* top: 152px; */
    /* left: 15px; */
    border-radius: 8px 0px 0px 0px;
    border-radius: 22px;
  
    transform: translateY(30%);
}








